import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useChatContext } from "../../../contexts/ChatContext";
import { renameChat } from "../../../server/renameChat";
import { getHistoric } from "../../../server/getHistoric";

/**
 * Componente de popup para renomear uma conversa no chat.
 *
 * @component
 * @param {Object} props - Propriedades do componente.
 * @param {string} props.chatUuid - UUID da conversa que será renomeada.
 * @param {string} props.chatSummary - Nome atual da conversa.
 * @returns {JSX.Element} O componente do popup de renomear conversa.
 */
export default function RenameChatPopup({ chatUuid, chatSummary }) {
  const {
    setRenamedChatId,
    setRenamedChatSummary,
    setChatData,
    resetPagination,
  } = useChatContext();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 10);

    return () => clearTimeout(timer); // Limpa o timer se o componente for desmontado
  }, []);

  const cancelButtonRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setRenamedChatId(undefined);
      setRenamedChatSummary(undefined);
    }, 200);
  };

  const [newChatSummary, setNewChatSummary] = useState();

  /**
   * Função para renomear a conversa e atualizar o histórico do chat.
   */
  const handleRename = () => {
    renameChat(chatUuid, newChatSummary)
      .then(() => {
        // Somente após a conclusão bem-sucedida de renameChat, getHistoric é chamado
        getHistoric()
          .then((response) => {
            setChatData(response);
            resetPagination();
          })
          .catch((error) => {
            console.error("Error fetching history after deleting chat:", error);
          });
      })
      .catch((error) => {
        console.error("Error renaming chat:", error);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="transform-gpu"
        >
          <div className="fixed inset-0 transition-opacity bg-slate-500 bg-opacity-5" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              className="transform-gpu"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-defaultWhite dark:bg-[#1d2432] px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                    <PencilSquareIcon
                      className="w-6 h-6 text-indigo-600 dark:text-indigo-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-slate-900 dark:text-slate-100"
                    >
                      Renomear conversa
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-slate-500 dark:text-slate-300">
                        Você gostaria de renomear esta conversa? Escolha um novo
                        nome para ela.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="rounded-md mt-5 px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-600 focus-within:ring-2 focus-within:ring-indigo-600">
                  <label
                    htmlFor="name"
                    className="block text-xs font-medium text-slate-900 dark:text-slate-100"
                  >
                    Novo nome
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    onChange={(e) => {
                      setNewChatSummary(e.target.value);
                    }}
                    className="block w-full border-0 p-0 text-slate-900 dark:text-slate-200 placeholder:text-slate-400 dark:placeholder:text-slate-300 dark:bg-[#1d2432] focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder={chatSummary}
                  />
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={() => {
                      handleRename();
                      handleClose();
                    }}
                  >
                    Renomear
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-defaultWhite dark:bg-[#1d2432] px-3 py-2 text-sm font-semibold text-slate-900 dark:text-slate-100 shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-600 hover:bg-slate-50 dark:hover:bg-slate-700 sm:col-start-1 sm:mt-0"
                    onClick={() => {
                      handleClose();
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancelar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
