import React, { useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useChatContext } from "../../contexts/ChatContext";

/**
 * Componente Slideover
 *
 * O componente `Slideover` exibe um painel deslizante que aparece da direita para a esquerda
 * com uma animação suave. Pode ser usado para exibir formulários, informações ou qualquer conteúdo
 * passado como `children`. Também contém um título e um botão para fechar o painel.
 *
 * @param {Object} props - Propriedades recebidas pelo componente.
 * @param {JSX.Element} props.children - O conteúdo a ser exibido dentro do Slideover.
 * @param {string} props.title - O título do painel que será exibido no topo.
 *
 * @returns {JSX.Element} Retorna um painel deslizante com animação.
 *
 * Exemplo de uso:
 * ```jsx
 * <Slideover title="Relatório de Problema">
 *   <ReportForm />
 * </Slideover>
 * ```
 */
export default function Slideover({ children, title }) {
  const [open, setOpen] = useState(false);
  const { setChangelogOpen, setReportForm } = useChatContext();

  /**
   * useEffect para abrir o painel com um pequeno atraso.
   * O painel é aberto após 10ms, e caso o componente seja desmontado, o temporizador é limpo.
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 10);

    return () => clearTimeout(timer); // Clean up the timer if the component unmounts
  }, []);

  /**
   * Função para fechar o painel com animação.
   * Após fechar o painel, define as flags de `setChangelogOpen` e `setReportForm` como `false` para limpar o estado.
   */
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setChangelogOpen(false);
      setReportForm(false);
    }, 302);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-300"
          enterFrom="opacity-0 translate-x-full"
          enterTo="opacity-100 translate-x-0"
          leave="transform transition ease-in-out duration-300"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-full"
          className="transform-gpu"
        >
          <div className="fixed inset-0" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
                beforeEnter={() => {
                  // Force GPU acceleration before the transition starts
                  document.querySelector(
                    ".transition-element"
                  ).style.willChange = "transform, opacity";
                }}
                afterLeave={() => {
                  // Clean up the will-change property after the transition ends
                  document.querySelector(
                    ".transition-element"
                  ).style.willChange = "";
                }}
              >
                <Dialog.Panel className="w-screen max-w-xl pointer-events-auto transition-element">
                  <div className="flex flex-col h-full py-6 overflow-y-scroll shadow-xl bg-defaultWhite dark:bg-slate-900 dark:text-slate-300">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 select-none text-slate-800 dark:text-slate-50">
                          {title}
                        </Dialog.Title>
                        <div className="flex items-center ml-3 h-7">
                          <button
                            type="button"
                            className="relative transition-colors duration-300 rounded-md text-slate-400 bg-defaultWhite dark:bg-slate-900 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={handleClose}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Fechar</span>
                            <XMarkIcon className="size-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative flex-1 px-4 mt-6 sm:px-6">
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
