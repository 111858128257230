import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
} from "react";
import { motion, AnimatePresence } from "framer-motion";
import { sendMessage } from "../../../server/sendMessage";
import { useChatContext } from "../../../contexts/ChatContext";
import { PromptModels } from "../PromptModels/PromptModels";

/**
 * Componente TextInput.
 *
 * @component
 *
 * @description
 * O `TextInput` é um componente que permite ao usuário enviar mensagens em um chat. Ele lida com o estado de digitação, valida a mensagem, envia-a ao servidor e recebe a resposta. O componente também oferece feedback visual, como alertas de mensagem vazia ou em espera, e exibe sugestões de prompts quando o histórico de mensagens está vazio. Adicionalmente, lida com diferentes estados, como erro de servidor, autenticação e modo de leitura.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {Function} props.onSendMessage - Função chamada quando o usuário envia uma mensagem.
 * @param {Function} props.onReceiveResponse - Função chamada quando a resposta da mensagem é recebida.
 *
 * @returns {JSX.Element} Um componente de input de texto para chat com funcionalidade de envio de mensagens e feedback visual.
 *
 * @example
 * const handleSendMessage = (message) => console.log("Mensagem enviada:", message);
 * const handleReceiveResponse = (response) => console.log("Resposta recebida:", response);
 * return <TextInput onSendMessage={handleSendMessage} onReceiveResponse={handleReceiveResponse} />;
 */
const TextInput = forwardRef(
  ({ onSendMessage, onReceiveResponse }, forwardedRef) => {
    const {
      chatData,
      setChatData,
      messages,
      currentChatProtocol,
      currentChatHistoric,
      currentChatKnowledge,
      setCurrentChatHistoric,
      setCurrentChatKnowledge,
      setCurrentChatProtocol,
      isTyping,
      setIsTyping,
      showServerError,
      setShowServerError,
      showReportScreen,
      reportConversation,
      setReportConversation,
      isPoll,
      onlyRead,
      notAuthenticated,
      setNotAuthenticated,
      currentChatType,
    } = useChatContext();

    const internalRef = useRef(null);
    const textareaRef = forwardedRef || internalRef;
    const [message, setMessage] = useState("");
    const [showEmptyMessageWarning, setShowEmptyMessageWarning] =
      useState(false);
    const [showWaitingMessageWarning, setShowWaitingMessageWarning] =
      useState(false);
    const [duringConversationSuggestions, setDuringConversationSuggestions] =
      useState([]);

    const autoResize = useCallback(() => {
      const textarea = textareaRef.current;
      if (!textarea) return;

      // Limite máximo de altura em pixels
      const maxHeight = 200;

      // Redefina a altura para permitir encolhimento ao apagar texto
      textarea.style.height = "40px";

      // Calcule a nova altura
      const newHeight = Math.min(textarea.scrollHeight, maxHeight);

      // Aplique a nova altura
      textarea.style.height = `${newHeight}px`;
    }, [textareaRef]);

    const trimmedMessage = message.trim();

    const chatHistory = chatData.history ? chatData.history : [];

    const sendMessageIfApplicable = useCallback(
      (prompt) => {
        const messageToSend = prompt || trimmedMessage;
        setDuringConversationSuggestions([]);

        if (messageToSend === "") {
          setShowEmptyMessageWarning(true);
        } else if (isTyping) {
          setShowWaitingMessageWarning(true);
        } else {
          setShowEmptyMessageWarning(false);
          setTimeout(() => {
            setIsTyping(true);
          }, 450);
          onSendMessage(messageToSend);

          if (!showReportScreen) {
            const currentProtocol = currentChatProtocol;
            sendMessage(
              messageToSend,
              currentProtocol,
              currentChatHistoric,
              currentChatKnowledge
            )
              .then((response) => {
                onReceiveResponse(response.answer);
                const protocolBase = currentChatProtocol;
                const chatIndex = chatHistory.findIndex((chat) =>
                  chat.protocol.startsWith(protocolBase)
                );
                const updatedChatHistory = [...chatHistory];

                if (response.suggestions && response.suggestions.length > 0) {
                  setDuringConversationSuggestions(response.suggestions);
                }

                if (chatIndex >= 0) {
                  const existingChatSummary = chatHistory[chatIndex].summary;
                  const newSummary = response.summary;

                  if (existingChatSummary !== newSummary) {
                    updatedChatHistory[chatIndex].summary = newSummary;
                  }
                  updateExistingChat(
                    currentProtocol,
                    response,
                    updatedChatHistory,
                    messageToSend
                  );
                } else {
                  createNewChat(response, messageToSend);
                }

                setChatData({ ...chatData, history: [...chatHistory] });
                setCurrentChatHistoric(
                  response.historic || currentChatHistoric
                );
                setCurrentChatKnowledge(
                  response.knowledge || currentChatKnowledge
                );
                setCurrentChatProtocol(
                  response.protocol || currentChatProtocol
                );
                setIsTyping(false);
              })
              .catch((error) => {
                if (error.message === "User is not authenticated") {
                  setNotAuthenticated(true);
                } else if (error.message === "Network Error") {
                  console.error("Error sending message:", error);
                  setShowServerError(true);
                }
                setTimeout(() => {
                  setIsTyping(false);
                }, 450);
              });
          } else {
            const userResponse = {
              role: "user",
              question: messageToSend,
              answer: "",
            };
            setReportConversation([...reportConversation, userResponse]);
          }

          setMessage("");
          autoResize();
        }
      },
      [
        trimmedMessage,
        isTyping,
        showReportScreen,
        currentChatProtocol,
        currentChatHistoric,
        currentChatKnowledge,
        chatHistory,
        setChatData,
        setCurrentChatHistoric,
        setCurrentChatKnowledge,
        setCurrentChatProtocol,
        setIsTyping,
        setNotAuthenticated,
        setShowServerError,
        onSendMessage,
        onReceiveResponse,
        autoResize,
      ]
    );

    const handleSelectPrompt = useCallback(
      (prompt) => {
        sendMessageIfApplicable(prompt);
      },
      [sendMessageIfApplicable]
    );

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "inherit";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }, [message, textareaRef]);

    useEffect(() => {
      if (!isTyping) {
        setShowWaitingMessageWarning(false);
      }
    }, [isTyping, isPoll]);

    useEffect(() => {
      if (!showReportScreen && textareaRef.current) {
        textareaRef.current.focus();
      }
    }, [showReportScreen, textareaRef]);

    const handleKeyDown = useCallback(
      (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          sendMessageIfApplicable();
        }
      },
      [sendMessageIfApplicable]
    );

    const handleChange = useCallback((e) => {
      setMessage(e.target.value);
    }, []);

    const isDisabled = useCallback(() => {
      return (
        notAuthenticated ||
        showServerError ||
        (isPoll &&
          (!currentChatProtocol ||
            Object.keys(currentChatProtocol).length === 0)) ||
        onlyRead ||
        currentChatType === "Report"
      );
    }, [
      notAuthenticated,
      showServerError,
      isPoll,
      currentChatProtocol,
      onlyRead,
      currentChatType,
    ]);

    function updateExistingChat(protocol, response, chatHistory, message) {
      const chatIndex = chatHistory.findIndex(
        (chat) => chat.protocol === protocol
      );
      chatHistory[chatIndex].historic = response.historic;
      chatHistory[chatIndex].knowledge = response.knowledge;
      if (chatIndex >= 0) {
        const newMessageId = chatHistory[chatIndex].messages.length;
        chatHistory[chatIndex].messages.push(
          {
            id: `${response.protocol}0000${newMessageId}`,
            date: new Date().toISOString(),
            role: "user",
            content: message,
            feedback: null,
          },
          {
            id: `${response.protocol}0000${newMessageId + 1}`,
            date: new Date().toISOString(),
            role: "assistant",
            content: response.answer,
            feedback: null,
          }
        );
      }
    }

    function createNewChat(response, message) {
      const newChat = {
        protocol: response.protocol,
        summary: response.summary || "Sumário do chat",
        pinned: false,
        historic: response.historic,
        knowledge: response.knowledge || "",
        messages: [
          {
            id: `${response.protocol}00000`,
            date: new Date().toISOString(),
            role: "user",
            content: message,
            feedback: null,
          },
          {
            id: `${response.protocol}00001`,
            date: new Date().toISOString(),
            role: "assistant",
            content: response.answer,
            feedback: null,
          },
        ],
      };
      chatHistory.unshift(newChat);
    }

    return (
      <motion.div
        className="flex flex-col items-center justify-center w-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <AnimatePresence>
          {showServerError && (
            <motion.div
              initial={{ opacity: 0, translateY: 10 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: 10 }}
              transition={{ duration: 0.2, ease: "easeOut" }}
              className="mb-2 text-red-400 text-center select-none text-[12px]"
            >
              Ocorreu um erro no servidor. Tente novamente mais tarde.
            </motion.div>
          )}
          {showEmptyMessageWarning && (
            <motion.div
              initial={{ opacity: 0, translateY: 10 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: 10 }}
              transition={{ duration: 0.2, ease: "easeOut" }}
              className="mb-2 text-slate-400 text-center select-none text-[12px]"
            >
              O campo de mensagem não pode ficar em branco. Digite algo antes de
              enviar.
            </motion.div>
          )}
          {showWaitingMessageWarning && (
            <motion.div
              initial={{ opacity: 0, translateY: 10 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: 10 }}
              transition={{ duration: 0.2, ease: "easeOut" }}
              className="mb-2 text-slate-400 text-center select-none text-[12px]"
            >
              Aguarde um instante, estamos trabalhando na resposta anterior.
            </motion.div>
          )}
          {(onlyRead || currentChatType === "Report") && (
            <motion.div
              initial={{ opacity: 0, translateY: 10 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: 10 }}
              transition={{ duration: 0.2, ease: "easeOut" }}
              className="mb-2 text-slate-400 text-center select-none text-[12px]"
            >
              Chat disponível apenas para consulta.
            </motion.div>
          )}
          {notAuthenticated && (
            <motion.div
              initial={{ opacity: 0, translateY: 10 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: 10 }}
              transition={{ duration: 0.2, ease: "easeOut" }}
              className="mb-2 text-red-400 text-center select-none text-[12px]"
            >
              Solicite acesso para enviar mensagens.
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {(messages.length === 0 ||
            (duringConversationSuggestions &&
              duringConversationSuggestions.length > 0)) && (
            <PromptModels
              onSelect={handleSelectPrompt}
              suggestions={duringConversationSuggestions}
            />
          )}
        </AnimatePresence>
        <div
          className={`relative flex items-center justify-center smallMobile:w-full horizontalTablet:w-[800px] p-2 border rounded-lg text-black overflow-hidden max-h-[300px] textarea-custom-focus transition-colors duration-300 dark:border-slate-600 ${
            isDisabled()
              ? "bg-[#f9fafb] dark:bg-[#1d2432]"
              : "bg-defaultWhite dark:bg-[#0b1120]"
          }`}
        >
          <textarea
            ref={textareaRef}
            value={message}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onInput={autoResize}
            style={{ maxHeight: "200px" }}
            rows="1"
            className={`w-full resize-none focus:outline-none border-none dark:text-slate-100 focus:ring-0 focus:border-none transition-colors duration-300 ${
              isDisabled()
                ? "bg-[#f9fafb] dark:bg-[#1d2432]"
                : "bg-defaultWhite dark:bg-[#0b1120]"
            }`}
            placeholder={showServerError ? "" : "Digite uma mensagem"}
            disabled={isDisabled()}
          />
          <div className="flex items-center justify-center ">
            <button
              aria-label="Enviar"
              className="p-2 ml-2 transition-colors duration-300 bg-slate-100 rounded-lg hover:bg-slate-300 dark:bg-slate-800 hover:dark:bg-slate-700 focus:outline-none disabled:pointer-events-none disabled:opacity-70 disabled:dark:bg-[#1d2432] disabled:bg-[#f9fafb]"
              onClick={() => {
                sendMessageIfApplicable();
              }}
              disabled={isDisabled()}
            >
              <div className="block dark:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#374151"
                  className="size-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 17a.75.75 0 0 1-.75-.75V5.612L5.29 9.77a.75.75 0 0 1-1.08-1.04l5.25-5.5a.75.75 0 0 1 1.08 0l5.25 5.5a.75.75 0 1 1-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0 1 10 17Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="hidden dark:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#ffffff"
                  className="size-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 17a.75.75 0 0 1-.75-.75V5.612L5.29 9.77a.75.75 0 0 1-1.08-1.04l5.25-5.5a.75.75 0 0 1 1.08 0l5.25 5.5a.75.75 0 1 1-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0 1 10 17Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </button>
          </div>
        </div>
        <div className="mt-2 text-slate-400 text-center text-[12px] select-none">
          O Tadeo pode cometer erros. Considere verificar informações
          importantes.
        </div>
      </motion.div>
    );
  }
);

export default TextInput;
