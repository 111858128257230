import "./ChatWindow.css";
import React, { useEffect, useRef, useState } from "react";
import ChatMessages from "./ChatMessages/ChatMessages";
import MainFrame from "./MainFrame/MainFrame";
import ReportWindow from "./ReportWindow/ReportWindow";
import TextInput from "./TextInput/TextInput";
import { useChatContext } from "../../contexts/ChatContext";
import { PromptModels } from "./PromptModels/PromptModels";

/**
 * Namespace para componentes do ChatWindow.
 * @namespace ChatWindow
 */

/**
 * Realiza a rolagem suave até o final do elemento.
 *
 * @memberof ChatWindow
 * @param {HTMLElement} element - O elemento a ser rolado.
 */
const scrollToBottomSmoothly = (element) => {
  const duration = 500;
  const start = element.scrollTop;
  const end = element.scrollHeight - element.clientHeight;
  const startTime = Date.now();

  const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

  const animateScroll = () => {
    const currentTime = Date.now();
    const elapsedTime = currentTime - startTime;
    const scrollPosition = easeInOutQuad(Math.min(1, elapsedTime / duration));
    element.scrollTop = start + (end - start) * scrollPosition;

    if (elapsedTime < duration) {
      requestAnimationFrame(animateScroll);
    }
  };

  animateScroll();
};

/**
 * Componente principal da janela de chat.
 *
 * O componente `ChatWindow` gerencia a exibição das mensagens do chat, a interação do usuário com o campo de entrada de texto, e a exibição da janela de relatório, se ativada. Ele lida com o estado de rolagem automática para garantir que o usuário veja as novas mensagens conforme são enviadas e recebidas.
 *
 * @memberof ChatWindow
 * @component
 * @returns {JSX.Element} O componente da janela de chat.
 *
 * @example
 * return <ChatWindow />;
 */
function ChatWindow() {
  const {
    messages,
    setMessages,
    showReportScreen,
    reportConversation,
    isTyping,
  } = useChatContext();

  const [isLoaded, setIsLoaded] = useState(false);
  const chatMessagesDivRef = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (isTyping) {
      scrollToBottomSmoothly(chatMessagesDivRef.current);
    }
  }, [isTyping]);

  useEffect(() => {
    if (
      chatMessagesDivRef.current &&
      (messages.length > 0 || reportConversation.length > 0)
    ) {
      setTimeout(() => {
        scrollToBottomSmoothly(chatMessagesDivRef.current);
      }, 100);
    }
    setIsLoaded(true);
  }, [messages, reportConversation]);

  useEffect(() => {
    const handleKeydown = (event) => {
      if (!showReportScreen && textareaRef.current) {
        textareaRef.current.focus();
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [showReportScreen]);

  /**
   * Adiciona uma nova mensagem do usuário ao estado de mensagens.
   *
   * @param {string} newMessage - A nova mensagem enviada pelo usuário.
   */
  const addUserMessage = (newMessage) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", question: newMessage },
    ]);
  };

  /**
   * Adiciona uma nova mensagem do assistente ao estado de mensagens.
   *
   * @param {string} newMessage - A nova mensagem recebida do assistente.
   */
  const addAssistantMessage = (newMessage) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "assistant", answer: newMessage },
    ]);
  };

  return (
    <div
      className={`relative h-full w-full flex flex-col mx-auto background-opacity dark:background-opacity ${
        isLoaded ? "fade-in" : ""
      }`}
    >
      {showReportScreen ? (
        <div
          className="flex justify-center flex-grow w-full p-4 mx-auto overflow-auto"
          ref={chatMessagesDivRef}
        >
          <ReportWindow />
        </div>
      ) : (
        <div
          ref={chatMessagesDivRef}
          className="relative flex justify-center flex-grow w-full px-4 pb-4 mx-auto overflow-auto"
        >
          {messages.length === 0 && (
            <>
              <MainFrame
                onPromptSelect={addUserMessage}
                onReceiveResponse={addAssistantMessage}
              />
            </>
          )}
          {messages.length !== 0 && <ChatMessages messages={messages} />}
        </div>
      )}

      {!showReportScreen && (
        <div className="flex flex-no-wrap justify-center p-4 ">
          <div>
            <TextInput
              ref={textareaRef}
              onSendMessage={addUserMessage}
              onReceiveResponse={addAssistantMessage}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatWindow;
