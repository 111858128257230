import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import AuthServer from "../../server/user-auth/Login";
import LoginError from "./LoginError/LoginError";
import MicrosoftIcon from "../../assets/microsoft-92.png";
import "./style.css";

/**
 * Componente de Login.
 *
 * O componente `Login` permite ao usuário realizar autenticação usando diferentes métodos, incluindo a conta Microsoft ou email e senha. Exibe mensagens de erro caso o login falhe.
 *
 * @component
 * @returns {JSX.Element} O componente de autenticação.
 */
export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginType, setLoginType] = useState("");
  const [showLoginError, setShowLoginError] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showButtons, setShowButtons] = useState(true);

  /**
   * Realiza login usando email e senha.
   *
   * @async
   * @function
   * @param {Event} e - Evento de envio do formulário.
   */
  const loginWithEmailAndPassword = async (e) => {
    try {
      await AuthServer.loginWithEmailAndPassword(email, password);
      window.location.href = "/";
    } catch (error) {
      setShowLoginError(true);
      console.error("Erro ao fazer login manualmente: ", error.message);
    }
  };

  /**
   * Realiza login com a conta Microsoft.
   *
   * @async
   * @function
   */
  const handleMicrosoftLogin = async () => {
    setLoginType("microsoft");
    setShowLoginError(false);
    try {
      await AuthServer.loginWithMicrosoft();
      window.location.href = "/";
    } catch (error) {
      setShowLoginError(true);
      console.error("Erro ao fazer login com a Microsoft: ", error.message);
    }
  };

  /**
   * Exibe o formulário de login por email e senha.
   */
  const handleShowForm = () => {
    setShowButtons(false);
  };

  /**
   * Volta para a tela com as opções de login.
   */
  const handleShowButtons = () => {
    setShowForm(false);
    setTimeout(() => {
      setShowButtons(true);
    }, 200);
  };

  /**
   * Submete o formulário de login com email e senha.
   *
   * @param {Event} e - Evento de envio do formulário.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoginType("loginAndPassword");
    setShowLoginError(false);
    loginWithEmailAndPassword();
  };

  return (
    <>
      {showLoginError && <LoginError loginType={loginType} />}
      <div className="flex flex-col justify-center flex-1 py-12 bg-cover min-h-dvh sm:px-6 lg:px-8 bg-authBackground">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-2xl font-bold leading-9 tracking-tight text-center text-slate-100 ">
            Tadeo – Assistente de Projetos
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-defaultWhite dark:bg-[#0b1120] px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <Transition
              show={showButtons}
              enter="transition-opacity duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setShowForm(true)}
            >
              <div className="grid grid-cols-1 gap-4 text-center">
                <span
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 text-slate-900 dark:text-slate-100"
                >
                  Colaborador RNP, entre usando sua conta Microsoft
                </span>
                <button
                  onClick={handleMicrosoftLogin}
                  className="flex w-full gap-3 justify-center rounded-md bg-indigo-700 dark:bg-defaultWhite px-3 py-1.5 text-sm font-semibold leading-6 dark:text-slate-800 text-slate-200 shadow-sm hover:bg-indigo-600 dark:hover:bg-slate-50  focus-visible:outline focus-visible:outline-2 ring-1 ring-inset ring-slate-300 dark:ring-slate-600 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-colors duration-200"
                >
                  <img src={MicrosoftIcon} alt="Microsoft" className="size-5" />
                  <span className="text-sm font-semibold leading-6">
                    Microsoft
                  </span>
                </button>
                <div className="relative mt-5">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-slate-200" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-defaultWhite dark:bg-[#0b1120] px-6 text-slate-900 dark:text-slate-100">
                      Ou
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4">
                  <button
                    onClick={handleShowForm}
                    className="mt-5 flex w-full items-center justify-center rounded-md bg-defaultWhite dark:bg-[#1f2937] px-3 py-2 text-sm font-semibold text-slate-900 dark:text-slate-100 shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-600 hover:bg-slate-50 dark:hover:bg-slate-700 focus-visible:ring-transparent transition-colors duration-200"
                  >
                    Email e senha
                  </button>
                </div>
              </div>
            </Transition>
            <Transition
              show={showForm}
              enter="transition-opacity duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <form className="space-y-6" action="#" onSubmit={handleSubmit}>
                <div className="flex items-center justify-between">
                  <div className="text-sm leading-6">
                    <div
                      className="flex font-semibold cursor-pointer dark:text-slate-200 dark:hover:text-slate-400"
                      onClick={handleShowButtons}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="mt-1 size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                        />
                      </svg>
                      <span className="ml-2">Voltar</span>
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-slate-900 dark:text-slate-100"
                  >
                    Endereço de email
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="block w-full rounded-md dark:bg-[#1f2937] border-0 py-1.5 text-slate-900 dark:text-slate-100 shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-600 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-slate-900 dark:text-slate-100"
                  >
                    Senha
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="block w-full rounded-md dark:bg-[#1f2937] border-0 py-1.5 text-slate-900 dark:text-slate-100 shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-600 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between"></div>
                <div>
                  <button
                    type="submit"
                    className="flex w-full items-center justify-center rounded-md bg-defaultWhite dark:bg-[#1f2937] px-3 py-2 text-sm font-semibold text-slate-900 dark:text-slate-100 shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-600 hover:bg-slate-50 dark:hover:bg-slate-700 focus-visible:ring-transparent"
                  >
                    Entrar
                  </button>
                </div>
              </form>
            </Transition>
          </div>
        </div>
      </div>
    </>
  );
}
