import {
  auth,
  provider,
  signInWithPopup,
  signInWithEmailAndPassword,
} from "../../database/FirebaseConnection";

const AuthServer = {
  loginWithMicrosoft: async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const email = user.email;
      const emailDomain = email.split("@")[1];
      if (!emailDomain || !emailDomain.includes("rnp")) {
        throw new Error(
          "O usuário não possui autorização para acessar o sistema."
        );
      }

      window.location.href = "/";
    } catch (error) {
      console.error("Erro ao realizar login com a Microsoft: ", error);
      throw new Error("Erro ao realizar login com a Microsoft");
    }
  },
  loginWithEmailAndPassword: async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error("Erro ao fazer login manualmente: ", error.message);
      throw new Error("Erro ao fazer login manualmente");
    }
  },
};

export default AuthServer;
