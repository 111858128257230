import React, { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";

/**
 * Componente SuccessDialog.
 *
 * @component
 *
 * @description
 * O componente `SuccessDialog` exibe uma notificação temporária de sucesso após uma ação ser concluída. Ele utiliza uma transição suave de entrada e saída para aparecer e desaparecer da tela. A notificação pode ser fechada manualmente pelo usuário clicando no ícone de fechar.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {Function} props.onClose - Função a ser chamada quando o diálogo for fechado.
 *
 * @returns {JSX.Element} O componente que exibe uma notificação de sucesso.
 *
 * @example
 * return <SuccessDialog onClose={() => {}} />;
 */
export default function SuccessDialog({ onClose }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 10);
  }, []);

  return (
    <>
      {/* Região de notificação global */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:items-start sm:p-6"
      >
        <div className="flex flex-col items-center w-full space-y-4 sm:items-end">
          {/* Painel de notificação */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="transform-gpu"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-defaultWhite dark:bg-[#1f2937] shadow-lg ring-1 ring-black dark:ring-slate-600 dark:sm:ring-[#1f2937] ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="w-6 h-6 text-green-400 dark:text-green-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-slate-900 dark:text-slate-200">
                      Feedback enviado com sucesso!
                    </p>
                    <p className="mt-1 text-sm text-slate-500 dark:text-slate-300">
                      Obrigado por contribuir com a melhoria do Tadeo.
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-defaultWhite dark:bg-[#1f2937] text-slate-400 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                        setTimeout(() => {
                          onClose();
                        }, 300);
                      }}
                    >
                      <span className="sr-only">Fechar</span>
                      <XMarkIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
