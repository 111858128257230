import FormData from "form-data";

/**
 * Envia um relatório com arquivos e informações adicionais.
 * @param {Array} files - Array de arquivos a serem enviados.
 * @param {string} username - Nome de usuário.
 * @param {string} firebaseUserId - ID do usuário no Firebase.
 * @param {string} sessionId - ID da sessão.
 * @param {string} comment - Comentário ou mensagem do relatório.
 * @returns {Promise} - Promessa com a resposta da requisição.
 * @throws {Error} - Se a requisição falhar.
 * @example sendClickUpReport(files, username, firebaseUserId, sessionId, comment)
 */
export async function sendClickUpReport(
  files,
  username,
  firebaseUserId,
  sessionId,
  comment
) {
  try {
    const formData = new FormData();

    files.forEach((file) => formData.append("reportFile", file));

    formData.append("username", username);
    formData.append("uid", firebaseUserId);
    formData.append("clarityUsername", username);
    formData.append("claritySessionUuid", sessionId);
    formData.append("reportMessage", comment);

    const response = await fetch("/api/report-issue", {
      method: "POST",
      body: formData,
    });

    // Verifica se a resposta é OK
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Espera pela resposta em JSON ou outro formato conforme necessário
    const data = await response.json(); // Supondo que o servidor retorna JSON

    // Retorna código de status e dados
    return {
      statusCode: response.status,
      data,
    };
  } catch (error) {
    throw error;
  }
}
