// import { generateDynamics } from "./dynamics.mjs";

export function deleteChat(chatProtocol) {
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append(
    "Authorization",
    `Basic ${process.env.REACT_APP_HISTORIC_KEY}`
  );
  // headers.append("x-secret-key", generateDynamics());

  var raw = JSON.stringify({
    protocol: `${chatProtocol}`,
  });

  var requestOptions = {
    method: "DELETE",
    headers: headers,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    "https://tadeo-services.apps.kloud.rnp.br/historic/chat/delete/",
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Deleting chat endpoint's response was not ok");
      }
      return response.json();
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
}
