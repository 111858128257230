import { appendIdToResponsibles } from "./common-functions/appendIdToResponsibles";

export default async function getProgramNames() {
  const headers = new Headers();
  const authKey = process.env.REACT_APP_REPORT_RECOVERY_KEY;
  headers.append("Authorization", "Basic " + authKey);

  const requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      "https://tadeo-services.apps.kloud.rnp.br/report/program",
      requestOptions
    );

    const data = await response.json();
    const processedData = appendIdToResponsibles(data.programs);

    return processedData;
  } catch (error) {
    console.error("Error fetching programs:", error);
    return [];
  }
}
