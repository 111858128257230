import { auth } from "../../database/FirebaseConnection.js";

function getUserInfo() {
  const user = auth.currentUser;

  if (user) {
    const { email, uid, photoURL } = user;
    const [username] = email.split("@");
    const [firstName, lastName] = username.split(".");
    const fullName = `${firstName} ${lastName}`;

    return { fullName, uid, email, photoURL };
  } else {
    return null;
  }
}

export default getUserInfo;
