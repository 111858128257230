import { auth } from "../../database/FirebaseConnection";
import { Navigate } from "react-router-dom";

export const logout = () => {
  return auth
    .signOut()
    .then(() => {
      return <Navigate to="/auth" />;
    })
    .catch((error) => {
      console.error("Network response was not ok:", error);
    });
};
