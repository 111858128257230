import React, { useEffect } from "react";
import Login from "../../components/Login/Login";
import { logout } from "../../server/user-auth/Logout";

/**
 * AuthPage - Página de autenticação que exibe o componente de login e executa a função de logout ao montar.
 *
 * @component
 *
 * O `AuthPage` é responsável por renderizar a interface de login da aplicação. Quando o componente é montado,
 * ele chama a função `logout` para garantir que qualquer sessão de usuário anterior seja encerrada.
 *
 * @returns {JSX.Element} - Um elemento `div` contendo o componente de login.
 *
 * @prop {function} logout - Função importada que desloga o usuário ao montar a página.
 * @prop {JSX.Element} Login - Componente que exibe o formulário de login para autenticação do usuário.
 *
 * @example
 * // Exemplo de uso do AuthPage:
 * import React from 'react';
 * import AuthPage from './AuthPage';
 *
 * const App = () => {
 *   return (
 *     <div className="app-container">
 *       <AuthPage />
 *     </div>
 *   );
 * };
 *
 * export default App;
 */

function AuthPage() {
  useEffect(() => {
    logout();
  }, []);

  return (
    <div className="h-full antialiased">
      <Login />
    </div>
  );
}

export default AuthPage;
