export default function pinChat(protocol, isChatPinned) {
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append(
    "Authorization",
    `Basic ${process.env.REACT_APP_HISTORIC_KEY}`
  );

  const action = isChatPinned ? "unpin" : "pin";

  var requestData = {
    protocol: protocol,
  };

  var requestOptions = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(requestData),
    redirect: "follow",
  };

  return fetch(
    `https://tadeo-services.apps.kloud.rnp.br/historic/chat/${action}/`,
    requestOptions
  )
    .then((response) => {
      if (response.status === 400) {
        return response.json();
      } else if (!response.ok && response.status !== 400) {
        throw new Error(`Status code: ${response.status}`);
      }
      return response.json();
    })
    .catch((error) => {
      throw error;
    });
}
