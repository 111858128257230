import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ClipboardIcon,
  HandThumbUpIcon,
  HandThumbDownIcon,
} from "@heroicons/react/24/outline";
import { useChatContext } from "../../../../contexts/ChatContext";
import UserAvatar from "../../../UserAvatar/UserAvatar";
import { copyToClipboard } from "../../../../server/common-functions/copyToClipboard";
import { sendMessageFeedback } from "../../../../server/sendMessageFeedback";
import SuccessDialog from "./SuccessDialog/SuccessDialog";

/**
 * Componente MobileMessageOptions.
 *
 * @component
 *
 * @description
 * O componente `MobileMessageOptions` exibe um modal de opções de mensagem para dispositivos móveis. Ele permite ao usuário copiar o conteúdo da mensagem ou dar feedback positivo ou negativo. As ações de feedback e cópia são seguidas de uma animação de transição e fechamento do modal.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {Object} props.message - A mensagem à qual as opções de feedback e cópia se aplicam.
 * @param {Function} props.onClose - Função chamada ao fechar o modal.
 *
 * @returns {JSX.Element} O componente que renderiza as opções de feedback e cópia para uma mensagem no modal.
 *
 * @example
 * const message = { id: 1, answer: "Aqui está sua resposta!" };
 * return <MobileMessageOptions message={message} onClose={() => {}} />;
 */
export default function MobileMessageOptions({ message, onClose }) {
  const [open, setOpen] = useState(false);
  const [allowClose, setAllowClose] = useState(false);
  const { setShowMobileMessagesOptions } = useChatContext();
  const [showSuccess, setShowSuccess] = useState(false);
  const initialMessage = useRef(message);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
      setAllowClose(false);
      setTimeout(() => {
        setAllowClose(true);
      }, 1000);
    }, 10);
  }, []);

  const handleClose = () => {
    if (allowClose) {
      setOpen(false);
      setTimeout(() => setShowMobileMessagesOptions(null), 300);
    }
  };

  const handleCopy = () => {
    copyToClipboard(initialMessage.current.answer);
    handleClose();
  };

  const handleFeedback = async (feedback) => {
    try {
      setShowSuccess(true);
      await sendMessageFeedback(initialMessage.current.id, feedback);
    } catch (error) {
    } finally {
      handleClose();
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 sm:hidden smallMobile:block"
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 flex items-center justify-center transition-opacity bg-slate-900 bg-opacity-5 backdrop-filter backdrop-blur-sm">
            <div className="p-4 max-h-[200px] max-w-[90%] bg-defaultWhite rounded-lg dark:bg-slate-800">
              {showSuccess && (
                <SuccessDialog onClose={() => setShowSuccess(false)} />
              )}
              <div className="flex justify-start w-full m-2 my-4 transition duration-100 ease-in-out smallMobile:select-none sm:select-text">
                <UserAvatar
                  chatting={true}
                  typing={false}
                  isFromAssistant={true}
                />
                <div
                  className={`flex items-center flex-wrap w-full text-[#242424] dark:text-slate-200 px-2 max-w-[750px]`}
                >
                  <div className="relative flex w-full">
                    <div className="absolute top-0 font-bold select-none">
                      Tadeo
                    </div>

                    <div className="mt-[20px] max-h-40 overflow-hidden break-all line-clamp-5">
                      {(initialMessage.current.answer || "")
                        .split("\n")
                        .map((line, index, array) => (
                          <React.Fragment key={index}>
                            {line}
                            {index !== array.length - 1 && <br />}
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform rounded-lg shadow-xl bg-defaultWhite dark:bg-slate-800 sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-slate-900 dark:text-slate-100"
                    >
                      Opções da mensagem
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-5 mb-3 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <nav className="flex flex-col flex-1" aria-label="Sidebar">
                    <ul className="-mx-2 space-y-1">
                      <li>
                        <div
                          onClick={handleCopy}
                          className="flex p-2 text-sm font-semibold leading-6 rounded-md cursor-pointer text-slate-700 dark:text-slate-300 hover:text-indigo-600 dark:hover:text-indigo-400 hover:bg-slate-50 dark:hover:bg-slate-700 group gap-x-3"
                        >
                          <ClipboardIcon
                            className="text-slate-400 size-6 dark:text-slate-200 group-hover:text-indigo-600 dark:group-hover:text-indigo-400 shrink-0"
                            aria-hidden="true"
                          />
                          Copiar
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            handleFeedback(1);
                            setOpen(false);
                            setTimeout(
                              () => setShowMobileMessagesOptions(null),
                              300
                            );
                          }}
                          className="flex p-2 text-sm font-semibold leading-6 rounded-md cursor-pointer text-slate-700 dark:text-slate-300 hover:text-indigo-600 dark:hover:text-indigo-400 hover:bg-slate-50 dark:hover:bg-slate-700 group gap-x-3"
                        >
                          <HandThumbUpIcon
                            className="text-slate-400 size-6 dark:text-slate-200 group-hover:text-indigo-600 dark:group-hover:text-indigo-400 shrink-0"
                            aria-hidden="true"
                          />
                          Resposta satisfatória
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            handleFeedback(0);
                            setOpen(false);
                            setTimeout(
                              () => setShowMobileMessagesOptions(null),
                              300
                            );
                          }}
                          className="flex p-2 text-sm font-semibold leading-6 rounded-md cursor-pointer text-slate-700 dark:text-slate-300 hover:text-indigo-600 dark:hover:text-indigo-400 hover:bg-slate-50 dark:hover:bg-slate-700 group gap-x-3"
                        >
                          <HandThumbDownIcon
                            className="text-slate-400 size-6 dark:text-slate-200 group-hover:text-indigo-600 dark:group-hover:text-indigo-400 shrink-0"
                            aria-hidden="true"
                          />
                          Resposta insatisfatória
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
