import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

/**
 * Componente PromptModels.
 *
 * @component
 *
 * @description
 * O componente `PromptModels` exibe uma seleção de sugestões de prompts para facilitar a interação com um sistema de chat ou busca de informações. Ele apresenta três sugestões geradas aleatoriamente ou recebidas via prop.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {Function} props.onSelect - Função chamada quando o usuário seleciona um dos prompts.
 * @param {boolean} props.onChatMessages - Indica se as sugestões estão sendo exibidas durante uma conversa em andamento.
 * @param {string[]} [props.suggestions] - Sugestões personalizadas para exibição, caso existam.
 *
 * @returns {JSX.Element} O componente que renderiza três prompts para interação do usuário.
 */
export const PromptModels = ({ onSelect, onChatMessages, suggestions }) => {
  const [firstPrompt, setFirstPrompt] = useState({});
  const [secondPrompt, setSecondPrompt] = useState({});
  const [thirdPrompt, setThirdPrompt] = useState({});

  useEffect(() => {
    if (suggestions && suggestions.length > 0) {
      // Se as sugestões foram passadas, usa elas diretamente
      setFirstPrompt({
        ...formatPrompt(suggestions[0]),
        fullText: suggestions[0],
      });
      if (suggestions.length > 1) {
        setSecondPrompt({
          ...formatPrompt(suggestions[1]),
          fullText: suggestions[1],
        });
      }
      if (suggestions.length > 2) {
        setThirdPrompt({
          ...formatPrompt(suggestions[2]),
          fullText: suggestions[2],
        });
      }
    } else {
      // Se não houver sugestões, usa os prompts padrões aleatórios
      const prompts = [
        {
          value:
            "O que você pode me dizer a respeito do projeto Infovia Alagoas?",
        },
        {
          value:
            "Quantas atividades do projeto NE Conectado – Barreiras - BA estão em andamento?",
        },
        {
          value:
            "Quais tarefas do projeto Infovia 01 de Oriximiná - PA tem um percentual de conclusão entre 20 e 80%?",
        },
        { value: "Quais projetos são da Coordenação de Backbone?" },
        { value: "Quantos projetos tem previsão de término pra 2024?" },
        { value: "Quantos projetos estão em fase de execução?" },
        {
          value: "Quais as próximas atividades da Infovia 01 – Rede Parintins?",
        },
        { value: "Quais projetos de 2024 são patrocinados pelo FNDCT e PAC?" },
      ];
      const shuffled = prompts.sort(() => 0.5 - Math.random());
      setFirstPrompt({
        ...formatPrompt(shuffled[0].value),
        fullText: shuffled[0].value,
      });
      setSecondPrompt({
        ...formatPrompt(shuffled[1].value),
        fullText: shuffled[1].value,
      });
      setThirdPrompt({
        ...formatPrompt(shuffled[2].value),
        fullText: shuffled[2].value,
      });
    }
  }, [suggestions]);

  const formatPrompt = (prompt) => {
    const maxChars = 80;
    const maxTitleChars = 25;
    let words = prompt.split(" ");
    let title = "";
    let detail = "";
    let currentLength = 0;

    for (let word of words) {
      if (currentLength + word.length + 1 <= maxTitleChars) {
        title += word + " ";
        currentLength += word.length + 1;
      } else {
        break;
      }
    }

    title = title.trim();
    detail = prompt.slice(title.length).trim();

    if (title.length + detail.length > maxChars) {
      let detailWords = detail.split(" ");
      detail = "";
      let detailLength = 0;
      for (let word of detailWords) {
        if (detailLength + word.length + 1 + title.length <= maxChars) {
          detail += word + " ";
          detailLength += word.length + 1;
        } else {
          break;
        }
      }
      detail = detail.trim();
      if (title.length + detail.length < prompt.length) {
        detail += "...";
      }
    }

    return { title, detail };
  };

  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 50);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, translateY: 50 }}
      animate={{ opacity: 1, translateY: 0 }}
      exit={{ opacity: 0, translateY: 50 }}
      transition={{ duration: 0.2, ease: "easeOut" }}
    >
      <div
        data-testid="prompt-models"
        className={`smallMobile:w-full smallMobile:h-[95px] horizontalTablet:w-[800px] horizontalTablet:h-[80px] mb-[10px] flex justify-between space-x-2 ${onChatMessages ? "sticky bottom-[-20px]" : ""}`}
      >
        <div
          role="button"
          tabIndex={0}
          className="smallMobile:flex-1 flex flex-col horizontalTablet:flex-1 bg-defaultWhite dark:bg-[#0b1120] border-[1px] border-slate-200 dark:border-slate-600 rounded-lg text-[#374151] dark:text-slate-200 font-bold p-2 text-sm cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-800 transition-colors duration-300"
          onClick={() => onSelect(firstPrompt.fullText)}
        >
          <span>{firstPrompt.title}</span>
          <span className="text-xs font-medium">{firstPrompt.detail}</span>
        </div>
        <div
          role="button"
          tabIndex={0}
          className="smallMobile:flex-1 flex flex-col horizontalTablet:flex-1 bg-defaultWhite dark:bg-[#0b1120] border-[1px] border-slate-200 dark:border-slate-600 rounded-lg text-[#374151] dark:text-slate-200 font-bold p-2 text-sm cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-800 transition-colors duration-300"
          onClick={() => onSelect(secondPrompt.fullText)}
        >
          <span>{secondPrompt.title}</span>
          <span className="text-xs font-medium">{secondPrompt.detail}</span>
        </div>
        {thirdPrompt.fullText && (
          <div
            role="button"
            tabIndex={0}
            className="hidden horizontalTablet:flex flex-col horizontalTablet:flex-1 bg-defaultWhite dark:bg-[#0b1120] border-[1px] border-slate-200 dark:border-slate-600 rounded-lg text-[#374151] dark:text-slate-200 font-bold p-2 text-sm cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-800 transition-colors duration-300"
            onClick={() => onSelect(thirdPrompt.fullText)}
          >
            <span>{thirdPrompt.title}</span>
            <span className="text-xs font-medium">{thirdPrompt.detail}</span>
          </div>
        )}
      </div>
    </motion.div>
  );
};
