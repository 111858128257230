const getPortugueseDateCategory = (dateStr) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const lastWeek = new Date(today);
  lastWeek.setDate(lastWeek.getDate() - 7);

  const messageDate = new Date(dateStr);

  if (messageDate.toDateString() === today.toDateString()) {
    return "Hoje";
  } else if (messageDate.toDateString() === yesterday.toDateString()) {
    return "Ontem";
  } else if (messageDate > lastWeek) {
    return "Últimos 7 dias";
  } else {
    return getPortugueseMonthName(
      `${messageDate.getFullYear()}-${(
        "0" +
        (messageDate.getMonth() + 1)
      ).slice(-2)}`
    );
  }
};

const getPortugueseMonthName = (dateStr) => {
  const monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const currentYear = new Date().getFullYear();
  const currentMonthIndex = new Date().getMonth();
  const lastMonthIndex = currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;
  const lastYear = currentMonthIndex === 0 ? currentYear - 1 : currentYear;

  const dateYear = parseInt(dateStr.substr(0, 4));
  const dateMonthIndex = parseInt(dateStr.substr(5, 2)) - 1;

  if (dateYear === currentYear && dateMonthIndex === currentMonthIndex) {
    return "Este mês";
  } else if (dateYear === lastYear && dateMonthIndex === lastMonthIndex) {
    return "Mês passado";
  } else {
    return monthNames[dateMonthIndex];
  }
};

const sortChatsByDateDesc = (chats) => {
  return chats.sort((a, b) => {
    const lastMessageA = a.messages[a.messages.length - 1];
    const lastMessageB = b.messages[b.messages.length - 1];
    return new Date(lastMessageB.date) - new Date(lastMessageA.date);
  });
};

const groupChatsByMonth = (history) => {
  const groups = {
    Fixado: [],
  };

  history.forEach((chat) => {
    if (chat.pinned) {
      groups.Fixado.push(chat);
    } else if (chat.messages.length > 0) {
      const lastMessage = chat.messages[chat.messages.length - 1];
      const category = getPortugueseDateCategory(lastMessage.date);

      if (!groups[category]) {
        groups[category] = [];
      }

      groups[category].push(chat);
    }
  });

  return groups;
};

const groupOrder = [
  "Fixado",
  "Hoje",
  "Ontem",
  "Últimos 7 dias",
  "Este mês",
  "Mês passado",
];
export const sortAndGroupChats = (history) => {
  let groupedChats = groupChatsByMonth(history);
  if (groupedChats.Fixado.length === 0) {
    delete groupedChats.Fixado;
  }
  Object.keys(groupedChats).forEach((group) => {
    groupedChats[group] = sortChatsByDateDesc(groupedChats[group]);
  });

  // Ordena os grupos de acordo com a ordem definida em groupOrder
  return Object.entries(groupedChats).sort(([groupA], [groupB]) => {
    const indexA = groupOrder.indexOf(groupA);
    const indexB = groupOrder.indexOf(groupB);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    if (indexA === -1 && indexB === -1) {
      return groupA.localeCompare(groupB);
    }

    return indexA !== -1 ? -1 : 1;
  });
};
