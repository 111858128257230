import React, { useState, useEffect } from "react";
import {
  PhotoIcon,
  PaperClipIcon,
  DocumentIcon,
} from "@heroicons/react/24/solid";
import { Transition } from "@headlessui/react";
import FileUploadError from "./FileUploadError/FileUploadError";

/**
 * Componente de upload de arquivo com suporte a arrastar e soltar, além de anexar arquivos manualmente.
 * Suporta verificação de tipos de arquivos permitidos e exibe mensagens de erro quando necessário.
 *
 * @component
 * @param {Object} props - As propriedades do componente.
 * @param {Function} props.onFileSelect - Função chamada quando novos arquivos são selecionados.
 * @param {Array} props.externalFiles - Lista de arquivos fornecidos externamente.
 * @returns {JSX.Element} O componente de upload de arquivo.
 */
export default function FileUpload({ onFileSelect, externalFiles }) {
  const [draggedOver, setDraggedOver] = useState(false);
  const [files, setFiles] = useState([]);
  const [isFileSet, setIsFileSet] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const validTypes = ["png", "jpg", "jpeg", "pdf", "docx", "mp4", "heic"];

  /**
   * Verifica se o arquivo possui um tipo válido.
   * @param {string} filename - Nome do arquivo.
   * @returns {boolean} True se o arquivo for válido, False caso contrário.
   */
  const isValidFileType = (filename) => {
    return validTypes.some((type) =>
      filename.toLowerCase().endsWith(`.${type}`)
    );
  };
  useEffect(() => {
    setFiles(externalFiles);
  }, [externalFiles]);

  /**
   * Lida com a seleção de arquivos, verificando tipos e atualizando o estado.
   * @param {Event} e - Evento do input.
   * @param {FileList} filesInput - Lista de arquivos selecionados.
   */
  const handleFiles = (e, filesInput) => {
    e.preventDefault();
    const fileList = filesInput; // Esta linha assume que filesInput é um FileList
    const selectedFiles = Array.from(fileList);
    const newValidFiles = selectedFiles.filter((file) =>
      isValidFileType(file.name)
    );
    if (newValidFiles.length > 0) {
      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles, ...newValidFiles];
        onFileSelect(updatedFiles); // Chama a função passada pelo ReportForm
        setIsFileSet(true);
        setErrorMessage(false);
        return updatedFiles;
      });
    } else if (selectedFiles.length > 0) {
      setErrorMessage("Tipo de arquivo não suportado.");
    }

    document.getElementById("file-upload").value = ""; // Limpa o campo input
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDraggedOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDraggedOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
    setDraggedOver(false);
  };

  const handleFileRemove = (index) => {
    const updatedFiles = files.filter((_, idx) => idx !== index);
    setFiles(updatedFiles);
    if (updatedFiles.length === 0) {
      setIsFileSet(false);
      document.getElementById("file-upload").value = "";
    }
  };

  return (
    <div className="col-span-full">
      {errorMessage && (
        <FileUploadError
          onclose={() => {
            setTimeout(() => {
              setErrorMessage(false);
            }, 300);
          }}
        />
      )}
      <label
        htmlFor="cover-photo"
        className="block text-sm font-medium leading-6 select-none text-slate-900 dark:text-slate-100"
      >
        Gostaria de anexar alguma evidência?
      </label>
      <div
        className="flex justify-center px-6 py-10 mt-2 border border-dashed rounded-lg border-slate-900/25 dark:border-slate-100/25"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {draggedOver ? (
          <div className="text-center">
            <PhotoIcon
              className="mx-auto text-slate-300 size-12 dark:text-slate-700"
              aria-hidden="true"
            />
            <div className="flex mt-4 text-sm leading-6 text-slate-600 dark:text-slate-400">
              <p className="pl-1">Solte o arquivo aqui</p>
            </div>
            <p className="text-xs leading-5 text-slate-600 dark:text-slate-400">
              PNG, JPG, PDF ou DOCX
            </p>
          </div>
        ) : (
          <div className="text-center">
            <PhotoIcon
              className="mx-auto text-slate-300 size-12 dark:text-slate-700"
              aria-hidden="true"
            />
            <div className="flex mt-4 text-sm leading-6 text-slate-600 dark:text-slate-400">
              <label
                htmlFor="file-upload"
                className="relative font-semibold text-indigo-600 transition-colors duration-300 rounded-md cursor-pointer bg-defaultWhite dark:bg-slate-900 dark:text-indigo-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500 hover:dark:text-indigo-300"
                onClick={() => {}}
              >
                <span>Enviar um arquivo</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  onChange={(e) => {
                    handleFiles(e, e.target.files); // Utilize e.target.files para acessar os arquivos selecionados
                    setIsFileSet(true);
                  }}
                  onDrop={(e) => handleFiles(e, e.dataTransfer.files)}
                  multiple
                />
              </label>
              <p className="pl-1">ou arraste e solte</p>
            </div>
            <p className="text-xs leading-5 text-slate-600 dark:text-slate-400">
              PNG, JPG, HEIC, PDF, DOCX ou MP4
            </p>
          </div>
        )}
      </div>
      {isFileSet &&
        files.map((file, index) => (
          <Transition
            key={index}
            show={true}
            enter="transition-opacity duration-300 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            unmount={true}
          >
            <li className="flex items-center justify-between pt-4 pl-4 pr-5 text-sm leading-6">
              <div className="flex items-center flex-1 w-0">
                {file.name.endsWith(".pdf") || file.name.endsWith(".docx") ? (
                  <DocumentIcon
                    className="flex-shrink-0 text-slate-400 size-5"
                    aria-hidden="true"
                  />
                ) : file.name.endsWith(".jpg") || file.name.endsWith(".png") ? (
                  <PhotoIcon
                    className="flex-shrink-0 text-slate-400 size-5"
                    aria-hidden="true"
                  />
                ) : (
                  <PaperClipIcon
                    className="flex-shrink-0 text-slate-400 size-5"
                    aria-hidden="true"
                  />
                )}
                <div className="flex flex-1 min-w-0 gap-2 ml-4">
                  <span className="font-medium truncate">{file.name}</span>
                </div>
              </div>
              <div className="flex flex-shrink-0 ml-4 space-x-4">
                <button
                  type="button"
                  className="font-medium text-indigo-600 transition-colors duration-300 rounded-md dark:text-indigo-400 hover:text-indigo-500 hover:dark:text-indigo-300"
                  onClick={() => handleFileRemove(index)}
                >
                  Remover
                </button>
              </div>
            </li>
          </Transition>
        ))}
    </div>
  );
}
