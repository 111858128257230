import React, { createContext, useContext, useState } from "react";

/**
 * Criação do contexto de sessão.
 * Este contexto será utilizado para gerenciar informações de sessão e usuário dentro da aplicação.
 */
const SessionContext = createContext(null);

/**
 * Provedor de contexto de sessão.
 * Fornece informações de sessão e funções para atualizar os estados relacionados ao usuário.
 *
 * @param {object} props - As propriedades do componente.
 * @param {ReactNode} props.children - Os componentes filhos que terão acesso ao contexto.
 * @param {string} props.sessionId - O ID da sessão atual.
 *
 * @returns {JSX.Element} O provedor de contexto de sessão.
 */
export const SessionProvider = ({ children, sessionId }) => {
  const [username, setUsername] = useState("");
  const [firebaseUserId, setFirebaseUserId] = useState("");

  return (
    <SessionContext.Provider
      value={{
        sessionId,
        username,
        setUsername,
        firebaseUserId,
        setFirebaseUserId,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);
