import getUserInfo from "../user-auth/UserInfo";
import { v4 as uuidv4 } from "uuid";

class WebSocketManager {
  constructor() {
    this.socket = null;
    this.userInfo = null;
    this.queue = [];
    this.pendingResponses = {}; // Para armazenar promessas pendentes
    this.messageCache = {}; // Para armazenar mensagens sem protocolo
  }

  async connect() {
    this.userInfo = await this.getUserInfo();
    if (!this.userInfo) {
      console.error("User not authenticated");
      return;
    }

    const { uid, email, fullName } = this.userInfo;
    const connectionKey = process.env.REACT_APP_CONNECTION_KEY;
    if (!connectionKey) {
      console.error("Connection key is not defined");
      return;
    }

    const wsUrl = `wss://tadeo-services.apps.kloud.rnp.br/chat/ws/${connectionKey}/${encodeURIComponent(email)}/${encodeURIComponent(fullName)}/${uid}`;

    this.socket = new WebSocket(wsUrl);

    this.socket.onopen = () => {
      this.queue.forEach(({ messageString, resolve, reject, trackingId }) => {
        this.socket.send(messageString);
        this.pendingResponses[trackingId] = resolve;
      });
      this.queue = [];
    };

    this.socket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      const trackingId = response.tempId || response.protocol;

      if (this.pendingResponses[trackingId]) {
        this.pendingResponses[trackingId](response);
        delete this.pendingResponses[trackingId];
      } else {
        console.warn(
          "No pending response or cached message for trackingId:",
          trackingId
        );
      }

      // Check if it's a new conversation without protocol
      if (!response.tempId && response.protocol) {
        const tempId = Object.keys(this.pendingResponses)[0];
        if (tempId) {
          this.pendingResponses[tempId](response);
          delete this.pendingResponses[tempId];
        }
      }
    };

    this.socket.onclose = () => {
      this.socket = null;
    };

    this.socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      console.error("WebSocket readyState:", this.socket.readyState);
    };
  }

  async getUserInfo() {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const userInfo = getUserInfo();
        if (userInfo) {
          clearInterval(interval);
          resolve(userInfo);
        }
      }, 1000); // Tenta a cada segundo
    });
  }

  sendMessage(
    message,
    currentChatProtocol = "",
    currentChatHistoric = [],
    currentChatKnowledge = {}
  ) {
    return new Promise((resolve, reject) => {
      const requestData = {
        question: message,
        protocol: currentChatProtocol || undefined,
        historic: currentChatHistoric,
        knowledge: currentChatKnowledge,
      };

      // Adicionar um tempId para mensagens sem protocolo
      const trackingId = currentChatProtocol ? undefined : uuidv4();
      requestData.tempId = trackingId;

      const messageString = JSON.stringify(requestData);

      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(messageString);

        if (!currentChatProtocol) {
          // Armazena a mensagem no cache se for uma nova conversa
          this.pendingResponses[trackingId] = resolve;
        } else {
          this.pendingResponses[currentChatProtocol] = resolve;
        }
      } else {
        this.queue.push({ messageString, resolve, reject, trackingId });
      }
    });
  }
}

const webSocketManager = new WebSocketManager();
export default webSocketManager;
