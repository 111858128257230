export async function sendMessageFeedback(messageId, feedback) {
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append(
    "Authorization",
    `Basic ${process.env.REACT_APP_HISTORIC_KEY}`
  );

  try {
    const response = await fetch(
      "https://tadeo-services.apps.kloud.rnp.br/historic/message/feedback/",
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({ message_id: messageId, feedback: feedback }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Não tentamos converter a resposta em JSON se não houver corpo
    return { success: true };
  } catch (error) {
    console.error("Failed to send message feedback:", error);
    throw error; // Re-throw the error so it can be handled by the caller
  }
}
