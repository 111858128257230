import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { useChatContext } from "../../../contexts/ChatContext";
import pinChat from "../../../server/pinChat";
import { getHistoric } from "../../../server/getHistoric";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

/**
 * Componente Ellipsis que exibe um menu de opções para gerenciar conversas no chat.
 *
 * @component
 * @param {Object} props
 * @param {boolean} props.isChatPinned - Indica se o chat está fixado.
 * @param {string} props.chatUuid - Identificador único da conversa.
 * @param {string} props.currentChatSummary - Resumo atual da conversa.
 * @returns {JSX.Element} O menu de opções do chat.
 *
 * @example
 * <Ellipsis
 *   isChatPinned={true}
 *   chatUuid={"12345"}
 *   currentChatSummary={"Conversa importante"}
 * />
 */
export default function Ellipsis({
  isChatPinned,
  chatUuid,
  currentChatSummary,
}) {
  const {
    setChatData,
    setDeletedChatId,
    setMobileNavBarOpen,
    setRenamedChatId,
    setRenamedChatSummary,
    resetPagination,
  } = useChatContext();

  const handleChatPin = () => {
    pinChat(chatUuid, isChatPinned).then(() => {
      setChatData((prevChatData) => {
        const updatedHistory = prevChatData.history.map((chat) => {
          if (chat.protocol === chatUuid) {
            return { ...chat, pinned: !isChatPinned };
          }
          return chat;
        });

        return { ...prevChatData, history: updatedHistory };
      });

      getHistoric()
        .then((response) => {
          setChatData(response);
          resetPagination();
        })
        .catch((error) => {
          console.error("Error fetching history after pinning chat:", error);
        });
    });
  };

  const handleDelete = () => {
    setDeletedChatId(chatUuid);
  };

  const handleRename = () => {
    setRenamedChatId(chatUuid);
    setRenamedChatSummary(currentChatSummary);
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center rounded-full text-slate-400 hover:text-slate-100 focus:outline-none ">
          <span className="sr-only">Abrir opções</span>
          <EllipsisVerticalIcon className="w-5 h-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-[100px] origin-top-right rounded-md bg-defaultWhite dark:bg-[#1f2937] font-medium shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active
                      ? "bg-slate-100 dark:bg-slate-700 text-slate-900 dark:text-slate-100"
                      : "text-slate-700 dark:text-slate-300",
                    "block px-4 py-2 text-sm transition-colors duration-300"
                  )}
                  onClick={() => {
                    handleChatPin();
                  }}
                >
                  {isChatPinned ? "Desfixar" : "Fixar"}
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active
                      ? "bg-slate-100 dark:bg-slate-700 text-slate-900 dark:text-slate-100"
                      : "text-slate-700 dark:text-slate-300",
                    "block px-4 py-2 text-sm transition-colors duration-300"
                  )}
                  onClick={() => {
                    setMobileNavBarOpen(false);
                    handleRename();
                  }}
                >
                  Renomear
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active
                      ? "bg-slate-100 dark:bg-slate-700 text-slate-900 dark:text-slate-100"
                      : "text-slate-700 dark:text-slate-300",
                    "block px-4 py-2 text-sm transition-colors duration-300"
                  )}
                  onClick={() => {
                    setMobileNavBarOpen(false);
                    handleDelete();
                  }}
                >
                  Excluir
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
