/**
 * NotFoundPage - Componente que exibe uma página de erro 404 (Página não encontrada).
 *
 * @component
 *
 * O `NotFoundPage` é uma página de erro 404 que informa ao usuário que a página procurada não foi encontrada.
 * Ele exibe uma mensagem amigável e oferece links para retornar à tela principal ou reportar um problema.
 *
 * @returns {JSX.Element} - A estrutura da página 404 com uma mensagem de erro e dois links: um para voltar à página principal e outro para reportar um problema.
 *
 * @example
 * // Exemplo de uso do NotFoundPage:
 * import React from 'react';
 * import NotFoundPage from './NotFoundPage';
 *
 * function App() {
 *   return (
 *     <div className="app-container">
 *       <NotFoundPage />
 *     </div>
 *   );
 * }
 *
 * export default App;
 */
export default function NotFoundPage() {
  return (
    <>
      <main className="grid min-h-full place-items-center bg-defaultWhite dark:bg-[#0b1120] px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600 dark:text-indigo-500">
            404
          </p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-slate-900 dark:text-slate-200 sm:text-5xl">
            Página não encontrada
          </h1>
          <p className="mt-6 text-base leading-7 text-slate-600 dark:text-slate-400">
            Perdão, mas não conseguimos encontrar a página que você procura.
          </p>
          <div className="flex items-center justify-center mt-10 gap-x-6">
            <a
              href="/"
              className="rounded-md bg-indigo-600 dark:bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm dark:hover:bg-indigo-400 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Voltar para a tela principal
            </a>
            <a
              href="https://forms.clickup.com/9007081222/f/8cdu9r6-6531/UKGERZK6AVNZ15HRQ6?Nome%20da%20tarefa=Sugest%C3%B5es%20e%20Melhorias"
              className="text-sm font-semibold text-slate-900 dark:text-slate-200"
              target="_blank"
              rel="noopener noreferrer"
            >
              Reportar problema <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </>
  );
}
