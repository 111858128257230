import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import "./style.css";

const LoadMessage = () => {
  const widths = ["w-3/4", "w-1/2", "w-full", "w-2/3"];
  const [currentWidths, setCurrentWidths] = useState(widths);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWidths((prevWidths) => {
        const newWidths = [...prevWidths];

        return newWidths.sort(() => Math.random() - 0.5);
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <motion.div
        className="pt-2 animate-pulse"
        initial={{ opacity: 0, width: "0%" }}
        animate={{ opacity: 1, width: "100%" }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <div
          className={`${currentWidths[0]} h-3.5 mb-2 bg-slate-300 dark:bg-slate-700 rounded transition-all duration-500`}
        ></div>
        <div
          className={`${currentWidths[1]} h-3.5 mb-2 bg-slate-300 dark:bg-slate-700 rounded transition-all duration-500`}
        ></div>
        <div
          className={`${currentWidths[2]} h-3.5 mb-2 bg-slate-300 dark:bg-slate-700 rounded transition-all duration-500`}
        ></div>
        <div
          className={`${currentWidths[3]} h-3.5 mb-2 bg-slate-300 dark:bg-slate-700 rounded transition-all duration-500`}
        ></div>
      </motion.div>
    </>
  );
};

export default LoadMessage;
