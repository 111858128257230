import webSocketManager from "./ws/WebSocketManager";

export function sendMessage(
  message,
  currentChatProtocol,
  currentChatHistoric,
  currentChatKnowledge
) {
  return webSocketManager.sendMessage(
    message,
    currentChatProtocol,
    currentChatHistoric,
    currentChatKnowledge
  );
}
