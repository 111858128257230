import getUserInfo from "./user-auth/UserInfo";

export const sendReportData = async (
  option,
  additionalInfo,
  referencePeriod
) => {
  return new Promise((resolve, reject) => {
    const userInfo = getUserInfo();

    const connectionKey = process.env.REACT_APP_CONNECTION_KEY;

    const userChatEmail = userInfo.email;
    const userChatName = userInfo.fullName;
    const userChatID = userInfo.uid;

    const wsUrl = `wss://tadeo-services.apps.kloud.rnp.br/report/ws/${connectionKey}/${userChatEmail}/${userChatName}/${userChatID}`;

    const ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      const message = {
        user_id: userInfo.uid,
        user_email: userInfo.email,
        user_name: userInfo.fullName,
        reportType: {
          option: option,
          additionalInfo: additionalInfo,
        },
        referencePeriod:
          option === "specific responsible" ? "ALL" : referencePeriod,
      };
      ws.send(JSON.stringify(message));
    };

    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);

      resolve(response);
      ws.close();
    };

    ws.onerror = (error) => {
      console.error("Erro no WebSocket:", error);
      reject(error);
    };

    ws.onclose = (event) => {
      if (event.wasClean) {
      } else {
      }
    };
  });
};
