export function capitalizeName(sentence) {
  const prepositions = [
    "de",
    "do",
    "da",
    "dos",
    "das",
    "a",
    "o",
    "e",
    "com",
    "em",
    "sem",
    "sob",
  ];

  // Dividir a sentença em palavras e processar o caso das preposições
  const words = sentence.toLowerCase().split(" ");

  // Se o nome tem mais de 4 palavras, utilizar apenas o primeiro e último nome
  if (words.length > 4) {
    const firstWord = words[0];
    const lastWord = words[words.length - 1];

    // Capitalizar a primeira palavra sempre e a última apenas se não for preposição
    return (
      capitalize(firstWord) +
      " " +
      (prepositions.includes(lastWord) ? lastWord : capitalize(lastWord))
    );
  }

  // Caso contrário, processar normalmente
  return words
    .map((word, index) => {
      // Manter preposições em minúsculas exceto se forem a primeira palavra
      if (index !== 0 && prepositions.includes(word)) {
        return word;
      }
      return capitalize(word);
    })
    .join(" ");
}

// Função auxiliar para capitalizar palavras
function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function getInitials(name) {
  const words = name.split(" ").filter((word) => word.trim() !== ""); // Remove espaços extras e palavras vazias
  if (words.length === 0) return ""; // Retorna vazio se não houver palavras
  if (words.length === 1) return words[0][0].toUpperCase(); // Retorna a inicial do único nome

  const firstInitial = words[0][0].toUpperCase(); // Primeira letra do primeiro nome
  const lastInitial = words[words.length - 1][0].toUpperCase(); // Primeira letra do último nome

  return firstInitial + lastInitial;
}
