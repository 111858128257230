import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  HandThumbUpIcon as HandThumbUpIconOutline,
  HandThumbDownIcon as HandThumbDownIconOutline,
  ClipboardIcon as ClipboardIconOutline,
} from "@heroicons/react/24/outline";
import {
  HandThumbUpIcon as HandThumbUpIconSolid,
  HandThumbDownIcon as HandThumbDownIconSolid,
  ClipboardIcon as ClipboardIconSolid,
} from "@heroicons/react/24/solid";
import { copyToClipboard } from "../../../../server/common-functions/copyToClipboard";
import { sendMessageFeedback } from "../../../../server/sendMessageFeedback";
import SuccessDialog from "./SuccessDialog/SuccessDialog";

/**
 * Componente MessageOptions.
 *
 * @component
 *
 * @description
 * O componente `MessageOptions` exibe ícones interativos de feedback e cópia para cada mensagem. O usuário pode dar um "like", "dislike" ou copiar o conteúdo da mensagem para a área de transferência. Utiliza animações do `framer-motion` para suavizar a transição dos ícones quando o mouse passa sobre eles.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {Object} props.message - A mensagem para a qual as opções são aplicadas, contendo `id` e `answer`.
 *
 * @returns {JSX.Element} O componente que exibe as opções de feedback e cópia para uma mensagem.
 *
 * @example
 * const message = { id: 1, answer: "Aqui está sua resposta!" };
 * return <MessageOptions message={message} />;
 */
export default function MessageOptions({ message }) {
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleMouseEnter = (icon) => {
    setHoveredIcon(icon);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  const handleFeedback = async (feedback) => {
    setShowSuccess(true);
    await sendMessageFeedback(message.id, feedback);
  };

  return (
    <>
      {showSuccess && <SuccessDialog onClose={() => setShowSuccess(false)} />}
      <div className="absolute top-0 right-0 hidden p-1 space-x-1 rounded-md bg-slate-50/90 dark:bg-slate-900/90 backdrop-blur-lg backdrop-filter ring-inset sm:flex ring-1 ring-slate-200/50 dark:ring-slate-600/50">
        <div
          onMouseEnter={() => handleMouseEnter("thumbsUp")}
          onMouseLeave={handleMouseLeave}
          className="cursor-pointer"
          onClick={() => handleFeedback(1)}
          aria-label="thumbs up"
        >
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: hoveredIcon === "thumbsUp" ? 0 : 1 }}
            transition={{ duration: 0.1 }}
            className="absolute"
          >
            <HandThumbUpIconOutline className="text-slate-600 dark:text-slate-300 size-[15px]" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: hoveredIcon === "thumbsUp" ? 1 : 0 }}
            transition={{ duration: 0.1 }}
          >
            <HandThumbUpIconSolid className="text-slate-600 dark:text-slate-300 size-[15px]" />
          </motion.div>
        </div>
        <div
          onMouseEnter={() => handleMouseEnter("thumbsDown")}
          onMouseLeave={handleMouseLeave}
          className="cursor-pointer"
          onClick={() => handleFeedback(0)}
          aria-label="thumbs down"
        >
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: hoveredIcon === "thumbsDown" ? 0 : 1 }}
            transition={{ duration: 0.1 }}
            className="absolute"
          >
            <HandThumbDownIconOutline className="text-slate-600 dark:text-slate-300 size-[15px]" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: hoveredIcon === "thumbsDown" ? 1 : 0 }}
            transition={{ duration: 0.1 }}
          >
            <HandThumbDownIconSolid className="text-slate-600 dark:text-slate-300 size-[15px]" />
          </motion.div>
        </div>
        <div
          onMouseEnter={() => handleMouseEnter("clipboard")}
          onMouseLeave={handleMouseLeave}
          className="cursor-pointer"
          aria-label="clipboard"
          onClick={() => {
            copyToClipboard(message.answer);
          }}
        >
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: hoveredIcon === "clipboard" ? 0 : 1 }}
            transition={{ duration: 0.1 }}
            className="absolute"
          >
            <ClipboardIconOutline className="text-slate-600 dark:text-slate-300 size-[15px]" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: hoveredIcon === "clipboard" ? 1 : 0 }}
            transition={{ duration: 0.1 }}
          >
            <ClipboardIconSolid className="text-slate-600 dark:text-slate-300 size-[15px]" />
          </motion.div>
        </div>
      </div>
    </>
  );
}
