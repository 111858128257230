import { appendIdToItems } from "./common-functions/appendIdToItems";

export default async function getProjectNames() {
  const headers = new Headers();
  const authKey = process.env.REACT_APP_REPORT_RECOVERY_KEY;
  headers.append("Authorization", "Basic " + authKey);

  const requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      "https://tadeo-services.apps.kloud.rnp.br/report/project",
      requestOptions
    );

    const data = await response.json();
    const transformedResponse = appendIdToItems(data);

    return transformedResponse;
  } catch (error) {
    console.error("Error fetching project names:", error);
    return [];
  }
}
