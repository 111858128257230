export async function downloadReport(reportLink) {
  try {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    const authKey = process.env.REACT_APP_REPORT_RECOVERY_KEY;

    headers.append("Authorization", "Basic " + authKey);

    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    if (reportLink.includes("reports/regenerate")) {
      reportLink = reportLink.split("reports/regenerate/?id=")[1];
    }

    const url = `https://tadeo-services.apps.kloud.rnp.br/report/regenerate/?id=${reportLink}`;

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error(`Erro no servidor: ${response.status}`);
    }

    const blob = await response.blob();

    const downloadUrl = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = downloadUrl;
    a.download = "Relatório – Tadeo.docx";
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Erro ao baixar o arquivo:", error);

    if (error instanceof TypeError && error.message === "Failed to fetch") {
      console.error("Possível problema de CORS ou de conectividade de rede.");
    } else {
      console.error("Outro tipo de erro ocorreu:", error);
    }
  }
}
