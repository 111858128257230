import React, { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { useChatContext } from "../../../contexts/ChatContext";
import Message from "../Message/Message";

/**
 * Componente ChatMessages.
 *
 * @component
 *
 * @description
 * O componente `ChatMessages` é responsável por exibir uma lista de mensagens em um chat,
 * lidando com animações de entrada e saída e exibindo estados de digitação, erro no servidor e autenticação.
 * Utiliza o contexto `ChatContext` para acessar variáveis de estado como `isTyping`, `showServerError` e `notAuthenticated`.
 *
 * O componente utiliza transições animadas para esconder e mostrar mensagens sempre que a lista de mensagens é alterada.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {Array<Object>} props.messages - Um array de objetos de mensagem a serem exibidos no chat.
 *
 * @returns {JSX.Element} O componente que renderiza a lista de mensagens e outros estados, como digitação e erros.
 *
 * @example
 * const messages = [
 *   { id: 1, text: "Olá, como posso ajudar?", sender: "assistant" },
 *   { id: 2, text: "Preciso de ajuda com uma dúvida.", sender: "user" }
 * ];
 *
 * return <ChatMessages messages={messages} />;
 */
function ChatMessages({ messages }) {
  const { isTyping, showServerError, notAuthenticated } = useChatContext();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (messages.length > 0) {
      // Primeiro esconde o conteúdo
      setShow(false);
      // Depois de um pequeno atraso, mostra o conteúdo novamente
      const timer = setTimeout(() => {
        setShow(true);
      }, 10);
      return () => clearTimeout(timer);
    }
  }, [messages]);

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <div className="w-[100%] flex justify-center">
        <div className="smallMobile:w-[100%] horizontalTablet:w-[800px] flex flex-col mt-[65px] transition ease-in-out duration-200">
          {messages.map((message, index) => (
            <Message key={index} message={message} reportWindow={false} />
          ))}

          <div className={`${isTyping ? "flex" : "hidden"} justify-start`}>
            <Message message={""} reportWindow={false} typing={true} />
          </div>

          <div
            className={`${showServerError ? "flex" : "hidden"} justify-start`}
          >
            <Message message={""} reportWindow={false} serverError={true} />
          </div>
          <div
            className={`${notAuthenticated ? "flex" : "hidden"} justify-start`}
          >
            <Message
              message={""}
              reportWindow={false}
              showNotAuthenticatedError={true}
            />
          </div>
        </div>
      </div>
    </Transition>
  );
}

export default ChatMessages;
