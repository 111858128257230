import React from "react";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";

/**
 * Componente ReportDownloadButton.
 *
 * @component
 *
 * @description
 * O componente `ReportDownloadButton` exibe um botão estilizado para o download de um relatório. Ele inclui um ícone de download e uma descrição do arquivo disponível, além de animações para o hover, que mudam as cores de fundo e texto.
 *
 * @returns {JSX.Element} O componente que renderiza um botão de download do relatório.
 *
 * @example
 * return <ReportDownloadButton />;
 */
export default function ReportDownloadButton() {
  return (
    <div className="mt-2 transition-colors duration-300 rounded-md cursor-pointer bg-slate-50/90 dark:bg-slate-900/90 hover:dark:bg-slate-800/70 hover:bg-slate-200/70 backdrop-blur-lg backdrop-filter ring-inset ring-1 ring-slate-200/50 dark:ring-slate-600/50 group">
      <div className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
        <div className="flex items-center flex-1 w-0">
          <DocumentArrowDownIcon
            className="flex-shrink-0 w-5 h-5 text-slate-400"
            aria-hidden="true"
          />
          <div className="flex flex-1 min-w-0 gap-2 ml-4">
            <span className="font-medium truncate">Relatório – Tadeo.docx</span>
          </div>
        </div>
        <div className="flex-shrink-0 ml-4">
          <div className="font-medium text-indigo-400 transition-colors duration-300 cursor-pointer group-hover:dark:text-indigo-300 group-hover:text-indigo-500">
            Baixar
          </div>
        </div>
      </div>
    </div>
  );
}
