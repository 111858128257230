export function renameChat(protocol, newName) {
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append(
    "Authorization",
    `Basic ${process.env.REACT_APP_HISTORIC_KEY}`
  );

  var raw = JSON.stringify({
    protocol: protocol,
    summary: newName,
  });

  var requestOptions = {
    method: "PUT",
    headers: headers,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    "https://tadeo-services.apps.kloud.rnp.br/historic/chat/rename/",
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
}
